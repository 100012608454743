import { hideOthers } from "aria-hidden";
import { RemoveScroll } from "react-remove-scroll";

import {
	type ComponentPropsWithoutRef,
	type ElementRef,
	type FC,
	type ReactNode,
	type RefObject,
	forwardRef,
	useCallback,
	useEffect,
	useRef,
	useState,
} from "react";
import { useComposedRefs } from "../compose-refs";
import type { Scope } from "../context";
import { createContextScope } from "../context";
import { DismissableLayer } from "../dismissable-layer";
import { useFocusGuards } from "../focus-guards";
import { FocusScope } from "../focus-scope";
import { useId } from "../id";
import { createPopperScope } from "../popper";
import * as PopperPrimitive from "../popper";
import { Portal as PortalPrimitive } from "../portal";
import { Presence } from "../presence";
import { composeEventHandlers } from "../primitive";
import { Primitive } from "../react-primitive";
import { Slot } from "../slot";
import { useControllableState } from "../use-controllable-state";

/* -------------------------------------------------------------------------------------------------
 * Popover
 * -----------------------------------------------------------------------------------------------*/

const POPOVER_NAME = "Popover";

type ScopedProps<P> = P & { __scopePopover?: Scope };
const [createPopoverContext, createPopoverScope] = createContextScope(
	POPOVER_NAME,
	[createPopperScope],
);
const usePopperScope = createPopperScope();

type PopoverContextValue = {
	triggerRef: RefObject<HTMLButtonElement | null>;
	contentId: string;
	open: boolean;
	onOpenChange(open: boolean): void;
	onOpenToggle(): void;
	hasCustomAnchor: boolean;
	onCustomAnchorAdd(): void;
	onCustomAnchorRemove(): void;
	modal: boolean;
};

const [PopoverProvider, usePopoverContext] =
	createPopoverContext<PopoverContextValue>(POPOVER_NAME);

interface PopoverProps {
	children?: ReactNode;
	open?: boolean;
	defaultOpen?: boolean;
	onOpenChange?: (open: boolean) => void;
	modal?: boolean;
}

const Popover: FC<PopoverProps> = (props: ScopedProps<PopoverProps>) => {
	const {
		__scopePopover,
		children,
		open: openProp,
		defaultOpen,
		onOpenChange,
		modal = false,
	} = props;
	const popperScope = usePopperScope(__scopePopover);
	const triggerRef = useRef<HTMLButtonElement>(null);
	const [hasCustomAnchor, setHasCustomAnchor] = useState(false);
	const [open = false, setOpen] = useControllableState({
		prop: openProp,
		defaultProp: defaultOpen,
		onChange: onOpenChange,
	});

	return (
		<PopperPrimitive.Popper {...popperScope}>
			<PopoverProvider
				scope={__scopePopover}
				contentId={useId()}
				triggerRef={triggerRef}
				open={open}
				onOpenChange={setOpen}
				onOpenToggle={useCallback(
					() => setOpen((prevOpen) => !prevOpen),
					[setOpen],
				)}
				hasCustomAnchor={hasCustomAnchor}
				onCustomAnchorAdd={useCallback(() => setHasCustomAnchor(true), [])}
				onCustomAnchorRemove={useCallback(() => setHasCustomAnchor(false), [])}
				modal={modal}
			>
				{children}
			</PopoverProvider>
		</PopperPrimitive.Popper>
	);
};

Popover.displayName = POPOVER_NAME;

/* -------------------------------------------------------------------------------------------------
 * PopoverAnchor
 * -----------------------------------------------------------------------------------------------*/

const ANCHOR_NAME = "PopoverAnchor";

type PopoverAnchorElement = ElementRef<typeof PopperPrimitive.PopperAnchor>;
type PopperAnchorProps = ComponentPropsWithoutRef<
	typeof PopperPrimitive.PopperAnchor
>;
interface PopoverAnchorProps extends PopperAnchorProps {}

const PopoverAnchor = forwardRef<PopoverAnchorElement, PopoverAnchorProps>(
	(props: ScopedProps<PopoverAnchorProps>, forwardedRef) => {
		const { __scopePopover, ...anchorProps } = props;
		const context = usePopoverContext(ANCHOR_NAME, __scopePopover);
		const popperScope = usePopperScope(__scopePopover);
		const { onCustomAnchorAdd, onCustomAnchorRemove } = context;

		useEffect(() => {
			onCustomAnchorAdd();
			return () => onCustomAnchorRemove();
		}, [onCustomAnchorAdd, onCustomAnchorRemove]);

		return (
			<PopperPrimitive.PopperAnchor
				{...popperScope}
				{...anchorProps}
				ref={forwardedRef}
			/>
		);
	},
);

PopoverAnchor.displayName = ANCHOR_NAME;

/* -------------------------------------------------------------------------------------------------
 * PopoverTrigger
 * -----------------------------------------------------------------------------------------------*/

const TRIGGER_NAME = "PopoverTrigger";

type PopoverTriggerElement = ElementRef<typeof Primitive.button>;
type PrimitiveButtonProps = ComponentPropsWithoutRef<typeof Primitive.button>;
interface PopoverTriggerProps extends PrimitiveButtonProps {}

const PopoverTrigger = forwardRef<PopoverTriggerElement, PopoverTriggerProps>(
	(props: ScopedProps<PopoverTriggerProps>, forwardedRef) => {
		const { __scopePopover, ...triggerProps } = props;
		const context = usePopoverContext(TRIGGER_NAME, __scopePopover);
		const popperScope = usePopperScope(__scopePopover);
		const composedTriggerRef = useComposedRefs(
			forwardedRef,
			context.triggerRef,
		);

		const trigger = (
			<Primitive.button
				type="button"
				aria-haspopup="dialog"
				aria-expanded={context.open}
				aria-controls={context.contentId}
				data-state={getState(context.open)}
				{...triggerProps}
				ref={composedTriggerRef}
				onClick={composeEventHandlers(props.onClick, context.onOpenToggle)}
			/>
		);

		return context.hasCustomAnchor ? (
			trigger
		) : (
			<PopperPrimitive.PopperAnchor asChild {...popperScope}>
				{trigger}
			</PopperPrimitive.PopperAnchor>
		);
	},
);

PopoverTrigger.displayName = TRIGGER_NAME;

/* -------------------------------------------------------------------------------------------------
 * PopoverPortal
 * -----------------------------------------------------------------------------------------------*/

const PORTAL_NAME = "PopoverPortal";

type PortalContextValue = { forceMount?: true };
const [PortalProvider, usePortalContext] =
	createPopoverContext<PortalContextValue>(PORTAL_NAME, {
		forceMount: undefined,
	});

type PortalProps = ComponentPropsWithoutRef<typeof PortalPrimitive>;
interface PopoverPortalProps {
	children?: ReactNode;
	/**
	 * Specify a container element to portal the content into.
	 */
	container?: PortalProps["container"];
	/**
	 * Used to force mounting when more control is needed. Useful when
	 * controlling animation with React animation libraries.
	 */
	forceMount?: true;
}

const PopoverPortal: FC<PopoverPortalProps> = (
	props: ScopedProps<PopoverPortalProps>,
) => {
	const { __scopePopover, forceMount, children, container } = props;
	const context = usePopoverContext(PORTAL_NAME, __scopePopover);
	return (
		<PortalProvider scope={__scopePopover} forceMount={forceMount}>
			<Presence present={forceMount || context.open}>
				<PortalPrimitive asChild container={container}>
					{children}
				</PortalPrimitive>
			</Presence>
		</PortalProvider>
	);
};

PopoverPortal.displayName = PORTAL_NAME;

/* -------------------------------------------------------------------------------------------------
 * PopoverContent
 * -----------------------------------------------------------------------------------------------*/

const CONTENT_NAME = "PopoverContent";

interface PopoverContentProps extends PopoverContentTypeProps {
	/**
	 * Used to force mounting when more control is needed. Useful when
	 * controlling animation with React animation libraries.
	 */
	forceMount?: true;
}

const PopoverContent = forwardRef<
	PopoverContentTypeElement,
	PopoverContentProps
>((props: ScopedProps<PopoverContentProps>, forwardedRef) => {
	const portalContext = usePortalContext(CONTENT_NAME, props.__scopePopover);
	const { forceMount = portalContext.forceMount, ...contentProps } = props;
	const context = usePopoverContext(CONTENT_NAME, props.__scopePopover);
	return (
		<Presence present={forceMount || context.open}>
			{context.modal ? (
				<PopoverContentModal {...contentProps} ref={forwardedRef} />
			) : (
				<PopoverContentNonModal {...contentProps} ref={forwardedRef} />
			)}
		</Presence>
	);
});

PopoverContent.displayName = CONTENT_NAME;

/* -----------------------------------------------------------------------------------------------*/

type PopoverContentTypeElement = PopoverContentImplElement;
interface PopoverContentTypeProps
	extends Omit<
		PopoverContentImplProps,
		"trapFocus" | "disableOutsidePointerEvents"
	> {}

const PopoverContentModal = forwardRef<
	PopoverContentTypeElement,
	PopoverContentTypeProps
>((props: ScopedProps<PopoverContentTypeProps>, forwardedRef) => {
	const context = usePopoverContext(CONTENT_NAME, props.__scopePopover);
	const contentRef = useRef<HTMLDivElement>(null);
	const composedRefs = useComposedRefs(forwardedRef, contentRef);
	const isRightClickOutsideRef = useRef(false);

	// aria-hide everything except the content (better supported equivalent to setting aria-modal)
	useEffect(() => {
		const content = contentRef.current;
		if (content) return hideOthers(content);
	}, []);

	return (
		<RemoveScroll as={Slot} allowPinchZoom>
			<PopoverContentImpl
				{...props}
				ref={composedRefs}
				// we make sure we're not trapping once it's been closed
				// (closed !== unmounted when animating out)
				trapFocus={context.open}
				disableOutsidePointerEvents
				onCloseAutoFocus={composeEventHandlers(
					props.onCloseAutoFocus,
					(event) => {
						event.preventDefault();
						if (!isRightClickOutsideRef.current)
							context.triggerRef.current?.focus();
					},
				)}
				onPointerDownOutside={composeEventHandlers(
					props.onPointerDownOutside,
					(event) => {
						const originalEvent = event.detail.originalEvent;
						const ctrlLeftClick =
							originalEvent.button === 0 && originalEvent.ctrlKey === true;
						const isRightClick = originalEvent.button === 2 || ctrlLeftClick;

						isRightClickOutsideRef.current = isRightClick;
					},
					{ checkForDefaultPrevented: false },
				)}
				// When focus is trapped, a `focusout` event may still happen.
				// We make sure we don't trigger our `onDismiss` in such case.
				onFocusOutside={composeEventHandlers(
					props.onFocusOutside,
					(event) => event.preventDefault(),
					{ checkForDefaultPrevented: false },
				)}
			/>
		</RemoveScroll>
	);
});

const PopoverContentNonModal = forwardRef<
	PopoverContentTypeElement,
	PopoverContentTypeProps
>((props: ScopedProps<PopoverContentTypeProps>, forwardedRef) => {
	const context = usePopoverContext(CONTENT_NAME, props.__scopePopover);
	const hasInteractedOutsideRef = useRef(false);
	const hasPointerDownOutsideRef = useRef(false);

	return (
		<PopoverContentImpl
			{...props}
			ref={forwardedRef}
			trapFocus={false}
			disableOutsidePointerEvents={false}
			onCloseAutoFocus={(event) => {
				props.onCloseAutoFocus?.(event);

				if (!event.defaultPrevented) {
					if (!hasInteractedOutsideRef.current)
						context.triggerRef.current?.focus();
					// Always prevent auto focus because we either focus manually or want user agent focus
					event.preventDefault();
				}

				hasInteractedOutsideRef.current = false;
				hasPointerDownOutsideRef.current = false;
			}}
			onInteractOutside={(event) => {
				props.onInteractOutside?.(event);

				if (!event.defaultPrevented) {
					hasInteractedOutsideRef.current = true;
					if (event.detail.originalEvent.type === "pointerdown") {
						hasPointerDownOutsideRef.current = true;
					}
				}

				// Prevent dismissing when clicking the trigger.
				// As the trigger is already setup to close, without doing so would
				// cause it to close and immediately open.
				const target = event.target as HTMLElement;
				const targetIsTrigger = context.triggerRef.current?.contains(target);
				if (targetIsTrigger) event.preventDefault();

				// On Safari if the trigger is inside a container with tabIndex={0}, when clicked
				// we will get the pointer down outside event on the trigger, but then a subsequent
				// focus outside event on the container, we ignore any focus outside event when we've
				// already had a pointer down outside event.
				if (
					event.detail.originalEvent.type === "focusin" &&
					hasPointerDownOutsideRef.current
				) {
					event.preventDefault();
				}
			}}
		/>
	);
});

/* -----------------------------------------------------------------------------------------------*/

type PopoverContentImplElement = ElementRef<
	typeof PopperPrimitive.PopperContent
>;
type FocusScopeProps = ComponentPropsWithoutRef<typeof FocusScope>;
type DismissableLayerProps = ComponentPropsWithoutRef<typeof DismissableLayer>;
type PopperContentProps = ComponentPropsWithoutRef<
	typeof PopperPrimitive.PopperContent
>;
interface PopoverContentImplProps
	extends Omit<PopperContentProps, "onPlaced">,
		Omit<DismissableLayerProps, "onDismiss"> {
	/**
	 * Whether focus should be trapped within the `Popover`
	 * (default: false)
	 */
	trapFocus?: FocusScopeProps["trapped"];

	/**
	 * Event handler called when auto-focusing on open.
	 * Can be prevented.
	 */
	onOpenAutoFocus?: FocusScopeProps["onMountAutoFocus"];

	/**
	 * Event handler called when auto-focusing on close.
	 * Can be prevented.
	 */
	onCloseAutoFocus?: FocusScopeProps["onUnmountAutoFocus"];
}

const PopoverContentImpl = forwardRef<
	PopoverContentImplElement,
	PopoverContentImplProps
>((props: ScopedProps<PopoverContentImplProps>, forwardedRef) => {
	const {
		__scopePopover,
		trapFocus,
		onOpenAutoFocus,
		onCloseAutoFocus,
		disableOutsidePointerEvents,
		onEscapeKeyDown,
		onPointerDownOutside,
		onFocusOutside,
		onInteractOutside,
		...contentProps
	} = props;
	const context = usePopoverContext(CONTENT_NAME, __scopePopover);
	const popperScope = usePopperScope(__scopePopover);

	// Make sure the whole tree has focus guards as our `Popover` may be
	// the last element in the DOM (beacuse of the `Portal`)
	useFocusGuards();

	return (
		<FocusScope
			asChild
			loop
			trapped={trapFocus}
			onMountAutoFocus={onOpenAutoFocus}
			onUnmountAutoFocus={onCloseAutoFocus}
		>
			<DismissableLayer
				asChild
				disableOutsidePointerEvents={disableOutsidePointerEvents}
				onInteractOutside={onInteractOutside}
				onEscapeKeyDown={onEscapeKeyDown}
				onPointerDownOutside={onPointerDownOutside}
				onFocusOutside={onFocusOutside}
				onDismiss={() => context.onOpenChange(false)}
			>
				<PopperPrimitive.PopperContent
					data-state={getState(context.open)}
					role="dialog"
					id={context.contentId}
					{...popperScope}
					{...contentProps}
					ref={forwardedRef}
					style={{
						...contentProps.style,
						// re-namespace exposed content custom properties
						...{
							"--squared-popover-content-transform-origin":
								"var(--squared-popper-transform-origin)",
							"--squared-popover-content-available-width":
								"var(--squared-popper-available-width)",
							"--squared-popover-content-available-height":
								"var(--squared-popper-available-height)",
							"--squared-popover-trigger-width":
								"var(--squared-popper-anchor-width)",
							"--squared-popover-trigger-height":
								"var(--squared-popper-anchor-height)",
						},
					}}
				/>
			</DismissableLayer>
		</FocusScope>
	);
});

/* -------------------------------------------------------------------------------------------------
 * PopoverClose
 * -----------------------------------------------------------------------------------------------*/

const CLOSE_NAME = "PopoverClose";

type PopoverCloseElement = ElementRef<typeof Primitive.button>;
interface PopoverCloseProps extends PrimitiveButtonProps {}

const PopoverClose = forwardRef<PopoverCloseElement, PopoverCloseProps>(
	(props: ScopedProps<PopoverCloseProps>, forwardedRef) => {
		const { __scopePopover, ...closeProps } = props;
		const context = usePopoverContext(CLOSE_NAME, __scopePopover);
		return (
			<Primitive.button
				type="button"
				{...closeProps}
				ref={forwardedRef}
				onClick={composeEventHandlers(props.onClick, () =>
					context.onOpenChange(false),
				)}
			/>
		);
	},
);

PopoverClose.displayName = CLOSE_NAME;

/* -------------------------------------------------------------------------------------------------
 * PopoverArrow
 * -----------------------------------------------------------------------------------------------*/

const ARROW_NAME = "PopoverArrow";

type PopoverArrowElement = ElementRef<typeof PopperPrimitive.PopperArrow>;
type PopperArrowProps = ComponentPropsWithoutRef<
	typeof PopperPrimitive.PopperArrow
>;
interface PopoverArrowProps extends PopperArrowProps {}

const PopoverArrow = forwardRef<PopoverArrowElement, PopoverArrowProps>(
	(props: ScopedProps<PopoverArrowProps>, forwardedRef) => {
		const { __scopePopover, ...arrowProps } = props;
		const popperScope = usePopperScope(__scopePopover);
		return (
			<PopperPrimitive.PopperArrow
				{...popperScope}
				{...arrowProps}
				ref={forwardedRef}
			/>
		);
	},
);

PopoverArrow.displayName = ARROW_NAME;

/* -----------------------------------------------------------------------------------------------*/

function getState(open: boolean) {
	return open ? "open" : "closed";
}

const Root = Popover;
const Anchor = PopoverAnchor;
const Trigger = PopoverTrigger;
const Portal = PopoverPortal;
const Content = PopoverContent;
const Close = PopoverClose;
const Arrow = PopoverArrow;

export {
	createPopoverScope,
	//
	Popover,
	PopoverAnchor,
	PopoverTrigger,
	PopoverPortal,
	PopoverContent,
	PopoverClose,
	PopoverArrow,
	//
	Root,
	Anchor,
	Trigger,
	Portal,
	Content,
	Close,
	Arrow,
};
export type {
	PopoverProps,
	PopoverAnchorProps,
	PopoverTriggerProps,
	PopoverPortalProps,
	PopoverContentProps,
	PopoverCloseProps,
	PopoverArrowProps,
};
